import { gql } from 'graphql-tag';
import FileFragment from '@fragments/shared/FileFragment';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

export default gql`
    fragment ContentDynamicSortableFragment on ContentDynamicSortable {
        dynamic
        feed {
            contentItemType
            contentCard {
                content {
                    ...SummaryFragment
                }
                overrideImage {
                    ...ImageSummaryFragment
                }
            }
            imageHeading {
                image {
                    ...ImageSummaryFragment
                }
                header {
                    headerTitle
                }
                subheader
                buttons {
                    button {
                        appearance
                        label
                        type
                        url
                        reference {
                            ...SummaryFragment
                        }
                        file {
                            ...FileFragment
                        }
                    }
                }
            }
        }
    }
    ${FileFragment}
    ${SummaryFragment}
    ${ImageSummaryFragment}
`;
